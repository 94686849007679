<template>
  <form-field
    :server-errors="error"
    :label="$t('components.coupon_input.coupon')"
    class="CouponInput"
  >
    <template #leftIcon>
      <v-icon
        :type="inputIcon"
        :class="{ 'has-text-success': (!isCheckingCoupon && hasCoupon), 'hf-spin': isCheckingCoupon }"
      />
    </template>
    <input
      v-model="couponInput"
      slot-scope="{ classes }"
      :class="classes"
      :placeholder="$t('components.coupon_input.coupon_code')"
      type="text"
      class="input"
    >
  </form-field>
</template>

<script>
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'
import { mapActions, mapGetters, mapState } from 'vuex'

/**
 * Renders the coupon input
 * @module CouponInput
 */
export default {
  name: 'CouponInput',
  mixins: [debounceMixin(['validateCoupon'], 150)],
  data () {
    return {
      couponInput: '',
      isCheckingCoupon: false,
      error: ''
    }
  },
  computed: {
    ...mapState('cx/payment', ['couponId']),
    ...mapGetters('cx/payment', ['hasCoupon']),
    /**
     * Shows a spinner while loading,
     * green check then coupon is applied
     * or red tag when an error occurs
     * @return {string}
     */
    inputIcon () {
      return this.isCheckingCoupon
        ? 'refresh'
        : (this.hasCoupon ? 'check' : 'tag')
    }
  },
  watch: {
    couponInput (value) {
      // clear the errors if we have no input value
      if (!value) this.error = ''
      this.validateCoupon(value)
    }
  },
  mounted () {
    this.couponInput = this.couponId
  },
  methods: {
    ...mapActions('cx/payment', ['checkCoupon']),
    /**
     * Gets called when the input value changes. Debounced.
     * @param {string} coupon
     * @return {*}
     */
    validateCoupon (coupon) {
      this.isCheckingCoupon = true
      this.$emit('loading', true)

      return this.checkCoupon(coupon)
        .then(() => {
          this.error = ''
          this.$emit('loading', false)
          this.isCheckingCoupon = false
        })
        .catch((error) => {
          if (error === 'cancel') return
          this.error = this.$safeGet(error, 'response.data.message', this.$t('errors.error'))
          this.$emit('loading', false)
          this.isCheckingCoupon = false
        })
    }
  }
}
</script>
