<template>
  <i18n path="pages.cx.payment.price_per_interval">
    <span slot="price">{{ formatMoney(amount, true, 'EUR', symbol) }}</span>
    <!--<span slot="interval">{{ $t(`general.${interval}`) }}</span>-->
    <span slot="interval">{{ $t(`general.month`) }}</span>
  </i18n>
</template>

<script>
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'

/**
 * @module PricePerInterval
 */
export default {
  name: 'PricePerInterval',
  mixins: [FormatMoneyMixin],
  props: {
    amount: {
      type: Number,
      default: 0
    },
    interval: {
      type: String,
      required: true
    },
    symbol: {
      type: String,
      required: true
    }
  }
}
</script>
