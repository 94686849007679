<template>
  <div class="CXPlanSection border-grey-light p-l-l p-r-l p-v-xl">
    <div class="columns is-mobile">
      <div class="column is-narrow m-t-s">
        {{ $t('pages.cx.payment.plan') }}:
      </div>
      <div class="column is-expanded">
        <form-field>
          <el-select v-model="chosenPlan">
            <el-option
              v-for="item in billablePaymentPlansForInterval"
              :key="item.slug"
              :label="item.name"
              :value="item.slug"
              :disabled="item.commitment.slug === subscription.commitmentSlug"
            />
          </el-select>
        </form-field>
        <div class="has-text-grey is-size-6 m-t-s has-nowrap">
          <price-per-interval
            :amount="currentPlanPricePerInstallment"
            :interval="interval"
            :symbol="currentPlanCurrency"
          />
        </div>
      </div>
      <div class="column is-narrow">
        <form-field>
          <input
            v-model.number="amountOfUsers"
            type="text"
            class="input is-width-16 has-text-centered"
          >
        </form-field>
        <div class="has-text-centered has-text-grey">
          {{ $t('pages.cx.payment.users') }}
        </div>
      </div>
      <div class="column has-text-grey">
        <div>{{ formatMoney(totalCostForUsersPerInstallment, true, 'EUR', currentPlanCurrency) }}</div>
        <div>
          +
          <i18n path="pages.cx.payment.tax_per_interval">
            <span slot="interval">{{ $t(`general.month`) }}</span>
          </i18n>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PricePerInterval from '@/components/cx/payment/PricePerInterval'
import { generateComputedSetterWithMethod } from '@hypefactors/shared/js/utils/componentUtilities'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'

const { mapGetters: cxGetters, mapState: cxState, mapMutations: cxMutations } = createNamespacedHelpers('cx/payment')

/**
 * @module CXPlanSection
 */
export default {
  name: 'CXPlanSection',
  components: { PricePerInterval },
  mixins: [FormatMoneyMixin],
  computed: {
    ...mapGetters(['subscription']),

    ...cxState(['plan', 'interval', 'users']),
    ...cxGetters([
      'totalCostForUsersPerInstallment',
      'currentPlanPricePerInstallment',
      'billablePaymentPlansForInterval',
      'currentPlanCurrency'
    ]),

    // generate get/set pairs for form inputs
    chosenPlan: generateComputedSetterWithMethod('plan', 'SET_PAYMENT_PLAN'),
    amountOfUsers: generateComputedSetterWithMethod('users', 'SET_USERS')
  },
  methods: {
    ...cxMutations([
      'SET_PAYMENT_PLAN',
      'SET_USERS'
    ])
  }
}
</script>
