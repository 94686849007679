<template>
  <div class="CXPaymentLayout">
    <c-x-base-navigation>
      <go-back-button slot="left" />
    </c-x-base-navigation>
    <div class="container container--padded p-t-xxl">
      <slot />
    </div>
  </div>
</template>

<script>
import CXBaseNavigation from '@/components/cx/CXBaseNavigation'
import GoBackButton from '@/components/cx/GoBackButton'

/**
 * @module CXPaymentLayout
 */
export default {
  name: 'CXPaymentLayout',
  components: { CXBaseNavigation, GoBackButton }
}
</script>
