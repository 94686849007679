<template>
  <c-x-payment-layout>
    <div class="ManageSubscription has-text-sans">
      <heading
        :serif="false"
        color="black"
        size="2"
        class="m-b-xl"
      >
        {{ $t('pages.cx.payment.manage_subscription') }}
      </heading>
      <div class="columns">
        <div class="column is-8">
          <c-x-plan-section class="m-b-l" />
          <!-- TODO: Will unhide after it is implemented on the API -->
          <!--<div class="m-b-m">{{ $t('pages.cx.payment.optional_addons') }}</div>-->
          <!--<c-x-addons-section/>-->
        </div>
        <div class="column is-4">
          <c-x-cart>
            <coupon-input @loading="isCheckingCoupon = $event" />

            <form-field
              :validator="$v.acceptTerms"
              :messages="{ checked: 'pages.cx.payment.terms_and_conditions_validation' }"
              class="m-b-xl"
            >
              <el-checkbox
                v-model="acceptTerms"
                data-testid="ManageSubscription__AcceptTerms"
                @change="$v.acceptTerms.$touch()"
              >
                <i18n path="pages.cx.payment.agree_terms_and_conditions">
                  <a
                    slot="link_to_terms"
                    :href="$getUrl('/terms-and-conditions/', 'company')"
                    target="_blank"
                    class="has-text-primary"
                  >
                    {{ $t('pages.cx.payment.agreements') }}
                  </a>
                </i18n>
              </el-checkbox>
            </form-field>

            <v-button
              :disabled="isCheckingCoupon"
              class="is-primary is-fullwidth"
              data-testid="ProceedToCheckoutButton"
              @click="proceedToCheckout"
            >
              {{ $t('pages.cx.payment.proceed_to_checkout') }}
            </v-button>
          </c-x-cart>
        </div>
      </div>
    </div>
  </c-x-payment-layout>
</template>

<script>
import { mapMutations } from 'vuex'

import CXCart from '@/components/cx/payment/CXCart'
import CXPaymentLayout from '@/router/layouts/CXPaymentLayout'
import CXPlanSection from '@/components/cx/payment/CXPlanSection'
// import CXAddonsSection from '@/components/cx/payment/CXAddonsSection'
import CouponInput from '@/components/cx/payment/CouponInput'

/**
 * @module ManageSubscription
 */
export default {
  name: 'ManageSubscription',
  components: {
    CouponInput,
    CXCart,
    // CXAddonsSection,
    CXPlanSection,
    CXPaymentLayout
  },
  data () {
    return {
      acceptTerms: false,
      isCheckingCoupon: false
    }
  },
  validations: {
    acceptTerms: { checked (v) { return !!v } }
  },
  created () {
    if (this.$route.query.plan) {
      this.setPaymentPlan(this.$route.query.plan)
    }
  },
  methods: {
    ...mapMutations('cx/payment', {
      setPaymentPlan: 'SET_PAYMENT_PLAN'
    }),
    proceedToCheckout () {
      this.$v.$touch()
      if (this.$v.$error) return

      this.$router.push({ name: 'cx.checkout' })
    }
  }
}
</script>
